/* tslint:disable */
/* eslint-disable */
/**
 * PIDS Feed API
 * PIDS Feed API
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { FeedFromJSON, FeedsFromJSON, } from '../models/index';
/**
 *
 */
export class FeedManagementApi extends runtime.BaseAPI {
    /**
     * Get the state of the current feeds
     */
    getFeedsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/feed`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FeedsFromJSON(jsonValue));
        });
    }
    /**
     * Get the state of the current feeds
     */
    getFeeds(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFeedsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Start the execution of a specific feed
     */
    startFeedRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling startFeed().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/feed/{id}/start`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FeedFromJSON(jsonValue));
        });
    }
    /**
     * Start the execution of a specific feed
     */
    startFeed(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.startFeedRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Stop the execution of a specific feed
     */
    stopFeedRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling stopFeed().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/feed/{id}/stop`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FeedFromJSON(jsonValue));
        });
    }
    /**
     * Stop the execution of a specific feed
     */
    stopFeed(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stopFeedRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
