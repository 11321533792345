/* tslint:disable */
/* eslint-disable */
/**
 * PIDS Feed API
 * PIDS Feed API
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeedFromJSON, FeedToJSON, } from './Feed';
/**
 * Check if a given object implements the Feeds interface.
 */
export function instanceOfFeeds(value) {
    if (!('feeds' in value) || value['feeds'] === undefined)
        return false;
    return true;
}
export function FeedsFromJSON(json) {
    return FeedsFromJSONTyped(json, false);
}
export function FeedsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'feeds': (json['feeds'].map(FeedFromJSON)),
    };
}
export function FeedsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'feeds': (value['feeds'].map(FeedToJSON)),
    };
}
