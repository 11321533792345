/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the LineColorRef interface.
 */
export function instanceOfLineColorRef(value) {
    if (!('background' in value) || value['background'] === undefined)
        return false;
    if (!('foreground' in value) || value['foreground'] === undefined)
        return false;
    return true;
}
export function LineColorRefFromJSON(json) {
    return LineColorRefFromJSONTyped(json, false);
}
export function LineColorRefFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'background': json['background'],
        'foreground': json['foreground'],
        'border': json['border'] == null ? undefined : json['border'],
    };
}
export function LineColorRefToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'background': value['background'],
        'foreground': value['foreground'],
        'border': value['border'],
    };
}
