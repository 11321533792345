/* tslint:disable */
/* eslint-disable */
/**
 * PIDS Feed API
 * PIDS Feed API
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const TaskExecutionStatusEnum = {
    Running: 'RUNNING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
};
/**
 * Check if a given object implements the TaskExecution interface.
 */
export function instanceOfTaskExecution(value) {
    if (!('timestamp' in value) || value['timestamp'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('message' in value) || value['message'] === undefined)
        return false;
    return true;
}
export function TaskExecutionFromJSON(json) {
    return TaskExecutionFromJSONTyped(json, false);
}
export function TaskExecutionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'timestamp': (new Date(json['timestamp'])),
        'status': json['status'],
        'message': json['message'],
    };
}
export function TaskExecutionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'timestamp': ((value['timestamp']).toISOString()),
        'status': value['status'],
        'message': value['message'],
    };
}
