/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineColorRefFromJSON, LineColorRefToJSON, } from './LineColorRef';
/**
 * Check if a given object implements the Line interface.
 */
export function instanceOfLine(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('agency' in value) || value['agency'] === undefined)
        return false;
    if (!('color' in value) || value['color'] === undefined)
        return false;
    if (!('schedule' in value) || value['schedule'] === undefined)
        return false;
    return true;
}
export function LineFromJSON(json) {
    return LineFromJSONTyped(json, false);
}
export function LineFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'number': json['number'] == null ? undefined : json['number'],
        'type': json['type'],
        'agency': json['agency'],
        'color': LineColorRefFromJSON(json['color']),
        'schedule': json['schedule'],
    };
}
export function LineToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'number': value['number'],
        'type': value['type'],
        'agency': value['agency'],
        'color': LineColorRefToJSON(value['color']),
        'schedule': value['schedule'],
    };
}
