/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const DisruptionCauseGroup = {
    General: 'GENERAL',
    Security: 'SECURITY',
    Technical: 'TECHNICAL',
    Traffic: 'TRAFFIC',
    Accident: 'ACCIDENT',
    Weather: 'WEATHER',
    Special: 'SPECIAL',
    Staff: 'STAFF',
    Various: 'VARIOUS',
    Undefined: 'UNDEFINED'
};
export function instanceOfDisruptionCauseGroup(value) {
    for (const key in DisruptionCauseGroup) {
        if (Object.prototype.hasOwnProperty.call(DisruptionCauseGroup, key)) {
            if (DisruptionCauseGroup[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function DisruptionCauseGroupFromJSON(json) {
    return DisruptionCauseGroupFromJSONTyped(json, false);
}
export function DisruptionCauseGroupFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DisruptionCauseGroupToJSON(value) {
    return value;
}
