/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StopFromJSON, StopToJSON, } from './Stop';
/**
 * Check if a given object implements the Calendar interface.
 */
export function instanceOfCalendar(value) {
    return true;
}
export function CalendarFromJSON(json) {
    return CalendarFromJSONTyped(json, false);
}
export function CalendarFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'operationalDays': json['operationalDays'] == null ? undefined : json['operationalDays'],
        'stops': json['stops'] == null ? undefined : (json['stops'].map(StopFromJSON)),
    };
}
export function CalendarToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'operationalDays': value['operationalDays'],
        'stops': value['stops'] == null ? undefined : (value['stops'].map(StopToJSON)),
    };
}
