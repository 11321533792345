/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineColorLineFromJSON, LineColorLineToJSON, } from './LineColorLine';
import { LineColorRefFromJSON, LineColorRefToJSON, } from './LineColorRef';
/**
 * Check if a given object implements the LineColor interface.
 */
export function instanceOfLineColor(value) {
    if (!('comment' in value) || value['comment'] === undefined)
        return false;
    if (!('line' in value) || value['line'] === undefined)
        return false;
    if (!('color' in value) || value['color'] === undefined)
        return false;
    return true;
}
export function LineColorFromJSON(json) {
    return LineColorFromJSONTyped(json, false);
}
export function LineColorFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'comment': json['comment'],
        'agency': json['agency'] == null ? undefined : json['agency'],
        'line': LineColorLineFromJSON(json['line']),
        'color': LineColorRefFromJSON(json['color']),
    };
}
export function LineColorToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'comment': value['comment'],
        'agency': value['agency'],
        'line': LineColorLineToJSON(value['line']),
        'color': LineColorRefToJSON(value['color']),
    };
}
