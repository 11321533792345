/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DisruptionCauseCategoryFromJSON, DisruptionCauseCategoryToJSON, } from './DisruptionCauseCategory';
import { DisruptionCauseGroupFromJSON, DisruptionCauseGroupToJSON, } from './DisruptionCauseGroup';
/**
 * Check if a given object implements the DisruptionCause interface.
 */
export function instanceOfDisruptionCause(value) {
    if (!('cause' in value) || value['cause'] === undefined)
        return false;
    if (!('category' in value) || value['category'] === undefined)
        return false;
    if (!('group' in value) || value['group'] === undefined)
        return false;
    return true;
}
export function DisruptionCauseFromJSON(json) {
    return DisruptionCauseFromJSONTyped(json, false);
}
export function DisruptionCauseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'cause': json['cause'],
        'category': DisruptionCauseCategoryFromJSON(json['category']),
        'group': DisruptionCauseGroupFromJSON(json['group']),
    };
}
export function DisruptionCauseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'cause': value['cause'],
        'category': DisruptionCauseCategoryToJSON(value['category']),
        'group': DisruptionCauseGroupToJSON(value['group']),
    };
}
